._63tkho0 {
  font-family: Suisse Intl;
  resize: none;
}
._63tkho0::placeholder {
  color: var(--text_placeholder);
}
._63tkho0:disabled {
  opacity: 0.5;
}
._63tkho1 {
  color: var(--text_contrast);
}
._63tkho2 {
  color: var(--text_rotate);
}