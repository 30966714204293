:root.bb.bb-broniboy {
  --background_basic: #FAFAFA;
  --background_purity: #FFFFFF;
  --background_purityDark: #232323;
  --background_rauch: #F2F2F2;
  --background_rotate: #000000;
  --background_rotate_opacity_34: #00000057;
  --background_rotate_opacity_7p5: #00000013;
  --background_rotate_opacity_5: #0000000D;
  --background_inactive: #7D7D7D;
  --background_basicModal: #FAFAFA;
  --text_contrast: #161616;
  --text_moon: #8A8A8A;
  --text_placeholder: #B2B2B2;
  --text_rotate: #FFFFFF;
  --broni: #EB6200;
  --lilac: #7B61FF;
  --lilacActive: #624ECC;
  --lilacAlert: #BAAEFD;
  --garden: #34C759;
  --garden_opacity_50: #34C7597F;
  --heaven: #007AFF;
  --redfire: #FF3B30;
  --redfire_opacity_75: #FF3B30B2;
  --sunflower: #FFDD00;
  --sunflower_opacity_50: #FFDD007F;
  --inherit: inherit;
  --transparent: transparent;
}
:root.bb.bb-bumerang {
  --background_basic: #FAFAFA;
  --background_purity: #FFFFFF;
  --background_purityDark: #232323;
  --background_rauch: #F2F2F2;
  --background_rotate: #000000;
  --background_rotate_opacity_34: #00000057;
  --background_rotate_opacity_7p5: #00000013;
  --background_rotate_opacity_5: #0000000D;
  --background_inactive: #7D7D7D;
  --background_basicModal: #FAFAFA;
  --text_contrast: #161616;
  --text_moon: #8A8A8A;
  --text_placeholder: #B2B2B2;
  --text_rotate: #FFFFFF;
  --broni: #EB6200;
  --lilac: #7B61FF;
  --lilacActive: #624ECC;
  --lilacAlert: #BAAEFD;
  --garden: #34C759;
  --garden_opacity_50: #34C7597F;
  --heaven: #007AFF;
  --redfire: #FF3B30;
  --redfire_opacity_75: #FF3B30B2;
  --sunflower: #FFDD00;
  --sunflower_opacity_50: #FFDD007F;
  --inherit: inherit;
  --transparent: transparent;
}