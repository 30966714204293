._11pyx3y0 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
._11pyx3y1 {
  background: var(--background_rotate);
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: none;
  outline: none;
  cursor: pointer;
}