.bb-app * {
  margin: 0;
  padding: 0;
  line-height: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}
:root.bb-app {
  font-size: 16px;
}
:root.bb-app.is-locked body {
  height: calc(var(--window-inner-height) - 1px);
  overflow: hidden;
}
.bb-app button, .bb-app a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}
@media screen and (max-width: 0px) {
  .bb .hidden-before-sm {
    display: none !important;
  }
}
@media screen and (max-width: 672px) {
  .bb .hidden-before-md {
    display: none !important;
  }
}
@media screen and (max-width: 1056px) {
  .bb .hidden-before-lg {
    display: none !important;
  }
}
@media screen and (max-width: 1312px) {
  .bb .hidden-before-xlg {
    display: none !important;
  }
}
@media screen and (max-width: 1584px) {
  .bb .hidden-before-max {
    display: none !important;
  }
}