._489grs2 {
  font-family: Suisse Intl;
}
._489grs2:disabled {
  opacity: 0.6;
  box-shadow: none;
}
._489grs2:active:not(._489grs2:disabled) {
  opacity: 0.9;
}
._489grs3 {
  --_489grs0: var(--background_rotate);
  --_489grs1: var(--background_purityDark);
  background-color: var(--_489grs0);
  color: var(--text_rotate);
}
._489grs3:active:not(._489grs3:disabled), ._489grs3:hover:not(._489grs3:disabled) {
  background-color: var(--_489grs1);
}
._489grs4 {
  --_489grs0: var(--background_purity);
  --_489grs1: var(--background_rauch);
  background-color: var(--_489grs0);
  color: var(--text_contrast);
}
._489grs4:active:not(._489grs4:disabled), ._489grs4:hover:not(._489grs4:disabled) {
  background-color: var(--_489grs1);
}
._489grs5 {
  --_489grs0: var(--background_purity);
  --_489grs1: var(--background_rauch);
  background-color: var(--_489grs0);
  color: var(--text_contrast);
  box-shadow: 0px 4px 13px 0px var(--background_rotate_opacity_7p5),0px 20px 80px 0px var(--background_rotate_opacity_5);
}
._489grs5:active:not(._489grs5:disabled), ._489grs5:hover:not(._489grs5:disabled) {
  background-color: var(--_489grs1);
}
._489grs6 {
  --_489grs0: var(--transparent);
  --_489grs1: var(--background_purity);
  background-color: var(--_489grs0);
  color: var(--text_rotate);
  box-shadow: inset 0px 0px 0px 1px var(--text_rotate);
}
._489grs6:active:not(._489grs6:disabled), ._489grs6:hover:not(._489grs6:disabled) {
  background-color: var(--_489grs1);
  color: var(--text_contrast);
}
._489grs7 {
  --_489grs0: var(--background_rauch);
  --_489grs1: var(--background_purity);
  background-color: var(--_489grs0);
  color: var(--text_contrast);
}
._489grs7:active:not(._489grs7:disabled), ._489grs7:hover:not(._489grs7:disabled) {
  background-color: var(--_489grs1);
}