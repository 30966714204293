.icy0dv0 {
  position: relative !important;
}
.icy0dv0 > div {
  width: 100%;
  height: 100%;
}
.icy0dv0 .marker-text-root .marker-text {
  opacity: 1;
}
.icy0dv1 {
  filter: grayscale();
}
.icy0dv2 {
  position: static !important;
}