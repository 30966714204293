._16lfqua3 {
  font-size: 0.9rem;
  line-height: 120%;
  font-weight: 450;
  font-family: Suisse Intl;
  color: var(--text_contrast);
  background-color: var(--background_purity);
  border: 1px solid var(--_16lfqua2);
  z-index: -1;
  padding-top: 0;
  padding-right: 8px;
  padding-bottom: 0;
  padding-left: calc(var(--_16lfqua0) + 2px);
  border-radius: var(--_16lfqua1);
  width: fit-content;
  height: calc(var(--_16lfqua1) + 2px);
  margin-left: -1px;
  margin-top: -1px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
._16lfqua4 {
  font-size: 1rem;
  font-weight: 600;
}
._16lfqua5 {
  font-size: 0.9rem;
}