@keyframes _18k038x0 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.2;
  }
}
._18k038x1 > div {
  width: 7%;
  height: 31%;
  position: absolute;
  left: 46%;
  top: 36%;
  background-color: currentColor;
  opacity: 0;
  border-radius: 100px;
  animation: _18k038x0 1s linear infinite;
}
._18k038x1 > div.bar1 {
  transform: rotate(0.1deg) translate(0, -110%);
  animation-delay: 0s;
}
._18k038x1 > div.bar2 {
  transform: rotate(-40deg) translate(0, -110%);
  animation-delay: -0.11s;
}
._18k038x1 > div.bar3 {
  transform: rotate(-80deg) translate(0, -110%);
  animation-delay: -0.22s;
}
._18k038x1 > div.bar4 {
  transform: rotate(-120deg) translate(0, -110%);
  animation-delay: -0.33s;
}
._18k038x1 > div.bar5 {
  transform: rotate(-160deg) translate(0, -110%);
  animation-delay: -0.44s;
}
._18k038x1 > div.bar6 {
  transform: rotate(-200deg) translate(0, -110%);
  animation-delay: -0.55s;
}
._18k038x1 > div.bar7 {
  transform: rotate(-240deg) translate(0, -110%);
  animation-delay: -0.66s;
}
._18k038x1 > div.bar8 {
  transform: rotate(-280deg) translate(0, -110%);
  animation-delay: -0.77s;
}
._18k038x1 > div.bar9 {
  transform: rotate(-320deg) translate(0, -110%);
  animation-delay: -0.88s;
}