.sfxw330 {
  font-family: Suisse Intl;
}
.sfxw330:disabled {
  opacity: 0.6;
  box-shadow: none;
}
.sfxw331 {
  color: var(--text_contrast);
}
.sfxw331:active, .sfxw331:hover {
  background-color: var(--background_rauch);
}
.sfxw332 {
  background-color: var(--background_rauch);
}
.sfxw331:hover .sfxw332 {
  background-color: var(--background_basic);
}