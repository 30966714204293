._1ppza8j0 {
  font-family: Suisse Intl;
}
._1ppza8j1 {
  color: var(--text_contrast);
}
._1ppza8j1::placeholder {
  color: var(--text_placeholder);
}
._1ppza8j1:disabled {
  opacity: 0.5;
}
._1ppza8j2 {
  color: var(--text_rotate);
}
._1ppza8j2::placeholder {
  color: var(--text_placeholder);
}
._1ppza8j2:disabled {
  opacity: 0.5;
}