.ohvfwo0 {
  display: inline-block;
}
.ohvfwo1 {
  display: inline;
}
.ohvfwo2 {
  display: block;
}
.ohvfwo3 {
  display: flex;
}
.ohvfwo4 {
  display: inline-flex;
}
.ohvfwo5 {
  white-space: nowrap;
}
.ohvfwo6 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
.ohvfwo7 {
  word-break: break-word;
}
.ohvfwo8 {
  word-break: break-all;
}
.ohvfwo9 {
  white-space: break-spaces;
}
.ohvfwoa {
  white-space: pre;
}
.ohvfwob {
  white-space: normal;
}
.ohvfwoc {
  white-space: nowrap;
}
.ohvfwod {
  white-space: pre-wrap;
}
.ohvfwoe {
  white-space: pre-line;
}
.ohvfwof {
  font-weight: inherit !important;
  color: inherit !important;
  font-size: inherit !important;
  letter-spacing: inherit !important;
  line-height: inherit !important;
}