._18e25fm0 {
  overscroll-behavior: none;
}
._18e25fm2 {
  padding-top: env(safe-area-inset-top, 0px);
  padding-right: env(safe-area-inset-right, 0px);
  padding-bottom: env(safe-area-inset-bottom, 0px);
  padding-left: env(safe-area-inset-left, 0px);
}
._18e25fm0.os-host {
  overflow: hidden !important;
}
._18e25fm0.fit-content-width .os-content {
  width: fit-content !important;
}
._18e25fm0.fit-content-height .os-content {
  height: fit-content !important;
}
._18e25fm0.os-theme-dark > .os-scrollbar .os-scrollbar-track .os-scrollbar-handle {
  background-color: var(--background_rotate_opacity_34);
}
._18e25fm0.os-theme-dark > .os-scrollbar .os-scrollbar-track .os-scrollbar-handle.active {
  background-color: var(--background_rotate);
}
._18e25fm0.os-theme-dark > .os-scrollbar .os-scrollbar-track .os-scrollbar-handle:hover {
  background-color: var(--background_rotate);
}
._18e25fm0.os-theme-dark > .os-scrollbar.os-scrollbar-vertical {
  width: 9px;
  padding-right: 2px;
  padding-left: 0;
}
._18e25fm0.os-theme-dark > .os-scrollbar.os-scrollbar-horizontal {
  height: 9px;
  padding-bottom: 2px;
  padding-top: 0;
}